body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

nav a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
}

footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}

section {
  padding: 20px;
}


.whatsapp-btn-container {
  position: fixed;
  right: 30px;
  opacity: 0;
  bottom: -50px;
  padding: 24px;
  animation: fade-up 1000ms forwards;
  animation-delay: 1000ms;
}

@keyframes fade-up {
  100% {
      bottom: 24px;
      opacity: 1;
  }
}

.whatsapp-btn-container .whatsapp-btn {
  font-size: 48px;
  color: #25d366;
  display: inline-block;
  transition: all 400ms;
}

.whatsapp-btn-container .whatsapp-btn:hover {
  transform: scale(1.2);
}

.whatsapp-btn-container span {
  position: absolute;
  top: 0;
  left: 4px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #075e54;
  transform: rotateZ(20deg) translateX(10px);
  opacity: 0;
  transition: all 400ms;
}

.whatsapp-btn-container .whatsapp-btn:hover + span {
  transform: rotateZ(0deg) translateX(0px);
  opacity: 1;
}